import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import StartPage from './views/StartPage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NotFound from './views/NotFound';
import ProjectsPage from './views/Projects';
import LoginPage from './views/Login';
import CVPage from './views/Lebenslauf';
import RegisterPage from './views/Register';
import ActivationPage from './views/Activation';
import ImpressumPage from './views/Impressum';

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartPage />,
    errorElement: <NotFound />,
  },
  {
    path: '/projects',
    element: <ProjectsPage />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/register',
    element: <RegisterPage />
  },
  {
    path: '/activate',
    element: <ActivationPage />
  },
  {
    path: '/cv',
    element: <CVPage />
  },
  {
    path: '/impressum',
    element: <ImpressumPage />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
