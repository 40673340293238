import { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helper } from '../lib/classes/Helper';
import { BackendConnector } from '../lib/classes/BackendConnector';

interface Notification {
    colorString: string;
    message: string;
}

interface ContactFormProps {

}

function ContactForm(props: ContactFormProps) {
    const backend = new BackendConnector();
    const [contactName, setContactName] = useState('');
    const [contactMail, setContactMail] = useState('');
    const [contactSubject, setContactSubject] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState<Notification>();

    function clearFields() {
        setContactName('');
        setContactMail('');
        setContactSubject('');
        setContactMessage('');
        const nameField = document.getElementById('nameField')! as HTMLInputElement;
        const mailField = document.getElementById('mailField')! as HTMLInputElement;
        const subjectField = document.getElementById('subjectField')! as HTMLInputElement;
        const messageField = document.getElementById('messageField')! as HTMLInputElement;
        nameField.value = '';
        mailField.value = '';
        subjectField.value = '';
        messageField.value = '';
    }

    function hitSubmit() {
        const allLengthOK = Helper.validateLength(contactName, 3) && Helper.validateLength(contactMail, 6) && Helper.validateLength(contactSubject, 3) && Helper.validateLength(contactMessage, 6);
        if(!allLengthOK) {
            setResponseMessage({
                colorString: 'is-danger',
                message: 'Aus Spamschutzgründen müssen alle Felder mindestens 3 (Mail und Text mindestens 6) Zeichen lang sein.'
            });
            return;
        }
        const mailOK = Helper.validateMail(contactMail);
        if(!mailOK) {
            setResponseMessage({
                colorString: 'is-danger',
                message: 'Die eingegebene Mailadresse ist nicht korrekt.'
            });   
            return;
        }
        backend.sendRequest('/contact', {
            method: 'POST',
            body: {
                contactName,
                contactSubject,
                contactMail,
                contactMessage
            },
            callback: (r) => {
                setResponseMessage({
                    colorString: 'is-success',
                    message: 'Deine Kontaktanfrage wurde übermittelt. Du erhälst in Kürze eine Bestätigung per Mail.'
                });  
                clearFields();
                return;
            }
        })
        // ! todo Send Real Message 
    }

    return (
        <>
            {responseMessage ? <div className={'notification ' + responseMessage.colorString}>{responseMessage.message}</div> : ''}
            <div className="field">
                <label htmlFor="nameField" className="label">Name</label>
                <div className="control has-icons-left">
                    <input 
                        type="text" 
                        className="input" 
                        placeholder='Name oder Firma' 
                        id='nameField' 
                        onChange={(ev) => setContactName(ev.target.value)}
                    />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                </div>
            </div>
            <div className="field">
                <label htmlFor="mailField" className="label">E-Mail Adresse</label>
                <div className="control has-icons-left">
                    <input 
                        type="text" 
                        className="input" 
                        placeholder='name@provider.tld' 
                        id='mailField' 
                        onChange={(ev) => setContactMail(ev.target.value)}
                    />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                </div>
            </div>
            <div className="field">
                <label htmlFor="subjectField" className="label">Betreff</label>
                <div className="control">
                    <input 
                        type="text" 
                        className="input" 
                        placeholder='Meine Anfrage zu...' 
                        id='subjectField' 
                        onChange={(ev) => setContactSubject(ev.target.value)}
                    />
                </div>
            </div>
            <div className="field">
                <label htmlFor="messageField" className="label">Nachricht</label>
                <div className="control">
                    <textarea 
                        name="messageField" 
                        id="messageField" 
                        className="textarea" 
                        onChange={(ev) => setContactMessage(ev.target.value)}    
                    />
                </div>
            </div>
            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-primary" onClick={hitSubmit}>Absenden</button>
                </div>
            </div>
        </>
    );
}

export default ContactForm;