import React, { useEffect } from 'react';
import 'bulma/css/bulma.min.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import logo from '../../images/logo-jf.png';


function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [theme, setTheme] = useState('dark');

    useEffect(() => {
        const themeFromStorage = localStorage.getItem('theme');
        if(themeFromStorage) {
            setTheme(themeFromStorage);
        }
    }, [])

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }    

    function switchTheme() {
        if(theme === 'dark') {
            setTheme('light');
            localStorage.setItem('theme', 'light');
        } else {
            setTheme('dark');
            localStorage.setItem('theme', 'dark');
        }
    }

    return (
        <nav className='navbar is-fixed-top has-shadow' role='navigation' aria-label='main navigation'>
            <Helmet>
                <html data-theme={theme}/>
            </Helmet>
            <div className="navbar-brand">
                <a href="/" className="navbar-item">
                    <img src={logo} alt='JF Logo' />
                </a>
                <button onClick={toggleMenu} className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="mainNavBar">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>

            <div id="mainNavBar" className={'navbar-menu' + (menuOpen ? ' is-active' : '')}>
                <div className="navbar-start">
                    <a href="/#contact" className="navbar-item">Kontakt</a>
                    {
                        /*
                            <a href="/projects" className="navbar-item">Projekte</a>
                            <a href="/cv" className="navbar-item">Lebenslauf</a>
                        */
                    }
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <button
                            onClick={switchTheme}
                        >
                            <span className="icon">
                                <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun}></FontAwesomeIcon>
                            </span>
                        </button>
                    </div>    
                    {
                        /*  
                    <div className="navbar-item">
                        <div className="buttons">
                            <a href="/login" className="button">Login</a>
                            <a href="/register" className="button is-primary">Registrieren</a>
                        </div>
                    </div>
                        */
                    }           
                </div>
            </div>
        </nav>
    );
}

export default Header;
