import React from 'react';
import 'bulma/css/bulma.min.css';
import MainLayout from '../components/layout/MainLayout';
import Breadcrumbs from '../components/Breadcrumbs';



function ImpressumPage() {
  return (
    <MainLayout
        pageTitle='Impressum'
    >
        <Breadcrumbs breadcrumbLevels={[{name: 'Rechtliches', relativeURL: '#'}]} />
        <h1 className="title is-1">Impressum</h1>
        <h4 className="title is-4">Allgemeine Informationen</h4>
        <h5 className="title is-5">Name und Anschrift des Betreibers</h5>
        <p className="block">
            Jan Fath <br />
            St. Ingberter Straße 110 <br />
            66123 Saarbrücken <br />
            Deutschland
        </p>
        <h5 className="title is-5">Inhaltlich verantwortlich</h5>
        <p className="block">
            Jan Fath <br />
            St. Ingberter Straße 110 <br />
            66123 Saarbrücken <br />
            Deutschland
        </p>      
        <h5 className="title is-5">Kontakt</h5>
        <a href="mailto:hallo@janfath.de">hallo@janfath.de</a>
        <p className="block">Telefon: +49 681 9100 5661</p>
    </MainLayout>
  );
}

export default ImpressumPage;
