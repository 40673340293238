import 'bulma/css/bulma.min.css';
import EmptyLayout from '../components/layout/EmptyLayout';
import { faArrowsRotate, faCode, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactForm from '../components/ContactForm';

function StartPage() {
  return (
    <EmptyLayout>
      <section className="hero is-primary is-large">
        <div className="hero-body">
          <div>
            <div className="title">Jan Fath</div>
            <div className="subtitle">Webentwickler, Linux-Administrator, DevOps-Spezialist</div>
          </div>
        </div>  
      </section>
      <section className='section is-medium'>
          <div className="container">
            <div className="columns mt-3">
              <div className="column has-text-centered">
                <span className="icon">
                    <FontAwesomeIcon icon={faServer} size='3x'></FontAwesomeIcon>
                </span>
                <h4 className="title is-4">Linux-Server Administration</h4>
                <p className="block">Im Rahmen meiner Ausbildung und meiner Festanstellung habe ich diverse Kenntnisse in der Installation, Verwaltung und Pflege von Linux-Server-Systemen unterschiedlicher Art aufgebaut.</p>
              </div>
              <div className="column has-text-centered">
                <span className="icon">
                    <FontAwesomeIcon icon={faCode} size='3x'></FontAwesomeIcon>
                </span>
                <h4 className="title is-4">Webentwicklung</h4>
                <p className="block">Durch meinen ursprünglichen Einstieg als Webentwickler habe ich auch hier fundierte Kenntnisse aufgebaut und kann durch meine Kenntnisse im DevOps-Bereich auch mit Kenntnissen im Bereich CI/CD sowie der Bereitstellung glänzen.</p>
              </div>
              <div className="column has-text-centered">
              <span className="icon">
                    <FontAwesomeIcon icon={faArrowsRotate} size='3x'></FontAwesomeIcon>
                </span>
                <h4 className="title is-4">Automatisierung</h4>
                <p className="block">Meine Kenntnisse im automatisieren unterschiedlicher Prozesse, egal ob es sich dabei um simple Tasks oder komplexe Schnittstellenaufgaben zwischen unterschiedlichen Systemen handelt, habe ich im Rahmen meiner langjährigen Erfahrung regelmäßig unter Beweis gestellt.</p>
              </div>
            </div>  
          </div>
      </section>
      <section className="hero is-primary" id="aboutMe">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title">Über mich</p>
            <p className="subtitle">Bereits seit vielen Jahren habe ich großen Spaß daran, mich mit der Kombination aus interessanten Technologien und nützlichen Funktionalitäten auseinanderzusetzen. Dabei bin ich schon oft auf scheinbar unlösbare Herausforderungen gestoßen, die ich auf unterschiedliche Weisen doch lösen konnte.</p> 
            <p className="subtitle">Neue Programme kennenlernen, mich mit neuen Technologien auseinandersetzen und mir neues Wissen für meinen Alltag aneignen. <br />Das alles gibt mir die Motivation, an dem was ich tue.</p>
          </div>
        </div>  
      </section>
      <section className="section is-medium" id="currentProjects">
        <div className="container">
            <h1 className="title is-3 has-text-centered">Aktuelle Projekte</h1>
            <h2 className="subtitle is-4 has-text-centered">Eine Auswahl meiner aktuellen Hobby-Projekte</h2>
            <div className="columns">
              <div className="column">
                <h5 className="title is-5 has-text-centered">FCS-Forum Liveticker</h5>
                <p className="block has-text-centered">Das <b>offizielle Forum des größten saarländischen Fußballvereins</b> - dem 1. FC Saarbrücken, für den auch mein Herz schlägt, war auf der Suche nach einer Liveticker-Lösung zur Einbindung in das Forum.</p>
                <p className="block has-text-centered">In Absprache mit den für das Forum verantwortlichen Personen habe ich einen Liveticker entworfen und programmiert, der an Spieltagen im Forum zu sehen ist und von den Foren-Administratoren live aus dem Ludwigsparkstadion befüllt wird.</p>
                <p className="has-text-centered">
                  <a className="button is-primary" href="https://forum.fcsaarbruecken.de">Zum Forum des 1. FC Saarbrücken</a>
                </p>
              </div>
              <div className="column">
                <h5 className="title is-5 has-text-centered">MythCode</h5>
                <p className="block has-text-centered">MythCode ist ein Forum zum gemeinsamen Austausch über verschiedenste Erfahrungen im IT-Bereich.</p>
                <p className="block has-text-centered">Egal ob Scripting & Programmierung, DevOps, Netzwerktechnik oder sonstige Erfahrungen und Probleme im IT-Bereich, MythCode ist der Ort zum gemeinsamen Austausch.</p>
                <p className="has-text-centered">
                  <a className="button is-primary" href="https://mythcode.xyz">Zur MythCode Community</a>
                </p>
              </div>
              <div className="column">
                <h5 className="title is-5 has-text-centered">T</h5>
              </div>
            </div>
        </div>
      </section>
      <section className="hero is-primary">
        <div className="hero-body">
          <div>
            
          </div>
        </div>  
      </section>
      <section className="section" id="contact">
        <div className="container">
          <h1 className="title is-3 has-text-centered">Kontakt</h1>
          <div className="columns">
            <div className="column">
              <ContactForm />
            </div>
            <div className="column">
              <p className="block has-text-right">Gerne auch direkt per E-Mail an:</p>
              <p className="block has-text-right"><a href="mailto:hallo@janfath.de">hallo@janfath.de</a></p>
            </div>
          </div>
        </div>
      </section>
    </EmptyLayout>
  );
}

export default StartPage;
